import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import { startCase } from 'lodash';

export const initialValues = {
    page1: {
        show: true,
    },
    footerText: {
        text: `Copyright ©${new Date().getFullYear()} Unstuck and On Target Course for Parents`,
    },
};

const Component = () => {
    let builderBlocks = [];

    for (const pageKey of Object.keys(initialValues)) {
        if (Object.hasOwnProperty.call(initialValues, pageKey)) {
            const dict = initialValues[pageKey];
            let fields = [];

            for (const key of Object.keys(dict)) {
                if (key === 'show') {
                    fields.push(
                        <Field
                            name={pageKey + '.' + key}
                            component={CccisdToggle}
                            label={`Show ${startCase(pageKey)}`}
                            key="show"
                        />
                    );
                    continue;
                }

                fields.push(
                    <Field
                        name={pageKey + '.' + key}
                        component={CccisdInput}
                        key={key}
                        label={key.includes('_') ? key.replace('_', '.') : startCase(key)}
                    />
                );
            }
            builderBlocks.push(
                <BuilderBlock title={startCase(pageKey)} key={pageKey}>
                    {fields}
                </BuilderBlock>
            );
        }
    }

    return <>{builderBlocks}</>;
};

export default reportTemplateBuilder({})(Component);
