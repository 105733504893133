import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import { initialValues } from '../Builder';
import devTagsQuery from './devTagsQuery.graphql';
import { client } from 'cccisd-apollo';
import _get from 'lodash/get';

// assets
import logo from './assets/Logos/logo.png';
import footerImage from './assets/Logos/footer_image.png';

const Fortress = window.cccisd.fortress;

const flexibilityText = [
    {
        name: 'FLDifficultyAccepting',
        wont: 'Has difficulty accepting new situations, new people, or new rules',
        cant: 'Their brain processes familiar information much better than new information.',
    },
    {
        name: 'FLDoesntAcceptFeedback',
        wont: "Doesn't accept feedback, corrections, or suggested edits on homework",
        cant: "Their brain has difficulty switching from their idea. This means they get stuck and cannot hear or appreciate the other person's point of view.",
    },
    {
        name: 'FLWontStopArguing',
        wont: "Won't stop arguing or correcting people",
        cant: "It's difficult for them to recognize that there could be two correct answers or ways of doing things.",
    },
    {
        name: 'FLTroubleManaging',
        wont: 'Has trouble managing unexpected changes in routines or has meltdowns if plans change',
        cant: 'They depend on things going as expected. The predictability and routine expectations have a calming effect on them, when the unexpected happens, they are easily overwhelmed.',
    },
    {
        name: 'FLDifficultyLettingGo',
        wont: 'Has difficulty letting go of an idea or repeatedly asks the same question',
        cant: 'Their brain is stuck in a repeated feedback loop.',
    },
    {
        name: 'FLTroubleLosing',
        wont: 'Has trouble losing in a game',
        cant: "Their brain gets stuck on specific expectations and outcomes. When those expectations are violated, they can't modulate their reaction. Losing a game violates those expectations.",
    },
    {
        name: 'FLWontStopTalking',
        wont: "Won't stop talking about a favorite topic even when everyone is bored with it",
        cant: "They thrive on routine and predictability and doesn't understand that other people aren't soothed and comforted by repetition the way they are.",
    },
    {
        name: 'FLWontStopActivity',
        wont: "Won't stop an activity when asked",
        cant: "They can't switch gears, and their brain requires lots of lead time and support to shift.",
    },
];

const organizationText = [
    {
        name: 'ORGSameMistakes',
        wont: 'Makes the same mistakes over and over again even after being told',
        cant: "Their brain doesn't integrate information easily and is slow to make connections between one situation or experience and the next.",
    },
    {
        name: 'ORGDoesntExpressIdeas',
        wont: "Doesn't express ideas clearly when writing or talking",
        cant: 'Organizing ideas and summing them up is hard for them.',
    },
    {
        name: 'ORGDoesntListen',
        wont: "Doesn't listen to or misses what is important and gets stuck on details",
        cant: 'Their brain prioritizes details over the main idea. This impacts their conversations, school work, etc.',
    },
    {
        name: 'ORGNitpicks',
        wont: 'Nitpicks and is rigid about rules and word meanings',
        cant: 'They have a literal understanding of words and rules and fails to take in the context.',
    },
    {
        name: 'ORGWontKeepOrganized',
        wont: 'Won\t keep their backpack, dresser, or locker organized',
        cant: 'Their brain is poor at keeping track of materials and prioritizing objects that are important.',
    },
    {
        name: 'ORGAlwaysLosing',
        wont: 'Is always losing things',
        cant: 'Their brain is poor at keeping track of materials and prioritizing objects that are important.',
    },
    {
        name: 'ORGOftenLeavesHW',
        wont: "Often leaves homework at school or doesn't hand in completed homework",
        cant: 'Their brain is poor at keeping track of materials and prioritizing objects that are important.',
    },
];

const planningText = [
    {
        name: 'PLDoesntPlanAhead',
        wont: "Doesn't plan ahead or has big plans but is unrealistic about what it takes to achieve them",
        cant: 'They have a poor understanding of time.',
    },
    {
        name: 'PLDoesntDoBig',
        wont: "Doesn't do big projects in a logical or sequential fashion",
        cant: "They can't understand the steps required to complete a task or loses sight of goals easily.",
    },
    {
        name: 'PLIsUnrealistic',
        wont: 'Is unrealistic about how long things will take',
        cant: 'They have a poor understanding of time.',
    },
    {
        name: 'PLDoesntGetStarted',
        wont: "Doesn't get started on a project when they say they will",
        cant: "They don't know where to start, have difficulty seeing the main goal or objective, and have a poor understanding of time.",
    },
];

const initiationText = [
    {
        name: 'INDoesntGetStarted',
        wont: "Doesn't get started without lots of prompting or nagging",
        cant: "They can't easily shift from one state to another and have trouble keeping a goal in mind.",
    },
    {
        name: 'INOftenProcrastinates',
        wont: 'Often procrastinates or is a "couch potato"',
        cant: "They don't know how to take the first step or what the first step is.",
    },
    {
        name: 'INHasTroubleGetting',
        wont: 'Has trouble getting going on a task even when they know how to do it',
        cant: "They can't easily envision what is required to get a task done.",
    },
    {
        name: '',
        wont: "Won't follow directions",
        cant: 'They have trouble remembering to do more than one or two steps in a multi-step direction.',
    },
    {
        name: '',
        wont: "Doesn't work independently on homework or other tasks",
        cant: 'They struggle to keep spoken instructions in mind long enough to follow them.',
    },
    {
        name: '',
        wont: 'Needs constant reminding to stay on track',
        cant: 'They can’t screen out distractions or prioritize what is most important.',
    },
    {
        name: '',
        wont: 'Struggles with doing routine activities, such as getting dressed, getting ready for bed, or setting the table, even though you know they know how',
        cant: 'Because of difficulties holding things in working memory, they lose track of goals. Because of attentional difficulties, they can’t screen out distractions.',
    },
];

const workingMemoryText = [
    {
        name: 'WMWontFollowDirection',
        wont: 'Won\t follow directions',
        cant: 'They have trouble remembering to do more than one or two steps in a multi-step direction.',
    },
    {
        name: 'WMDoesntWorkIndependently',
        wont: "Doesn't work independently on homework or other tasks",
        cant: 'They struggle to keep spoken instructions in mind long enough to follow them.',
    },
    {
        name: 'WMNeedsConstant',
        wont: 'Needs constant reminding to stay on track',
        cant: "They can't screen out distractions or prioritize what is most important.",
    },
    {
        name: 'WMStrugglesDoing',
        wont: 'Struggles with doing routine activities, such as getting dressed, getting ready for bed, or setting the table, even though you know they know how',
        cant: "Because of difficulties holding things in working memory, they lose track of goals. Because of attentional difficulties, they can't screen out distractions.",
    },
];

const inhibitionText = [
    {
        name: 'IHDemandInstant',
        wont: 'Demands instant gratification',
        cant: 'They have brain-based difficulty with self-control or “putting the brakes on.”',
    },
    {
        name: 'IHRushesThrough',
        wont: "Rushes through tasks without stopping to think about how they're doing",
        cant: "They can't slow down their response.",
    },
    {
        name: 'IHProducesSloppy',
        wont: 'Produces sloppy work',
        cant: "They can't slow down their response.",
    },
    {
        name: 'IHInterruptsPeople',
        wont: 'Interrupts People',
        cant: "They have brain-based difficulty with self-control or “putting the brakes on” and doesn't understand how annoying it is to be interrupted.",
    },
    {
        name: 'IHIsImpulsive',
        wont: 'Is impulsive',
        cant: "They can't control impulses.",
    },
    {
        name: 'IHOftenDoesSomething',
        wont: 'Often does something without thinking and then regrets it',
        cant: "They can't control impulses.",
    },
];

const selfMonitoringText = [
    {
        name: 'SMHatesBeingTold',
        wont: 'Hates being told to check or show their work',
        cant: "It's difficult for them to articulate the process they use to get to the answer.",
    },
    {
        name: 'SMOftenSeemsConfused',
        wont: 'Often seems confused by the question, "Why did you do that?"',
        cant: 'They lack skills to observe and understand their own behavior and its impact on others.',
    },
    {
        name: 'SMMissesSigns',
        wont: "Misses signs that they're annoying others",
        cant: "They lacks skills to observe and understand their own behavior and its impact on others. It's difficult for them to read the non-verbal cues of others.",
    },
    {
        name: 'SMFailsToLearn',
        wont: 'Fails to learn from their mistakes',
        cant: "It's difficult for them to observe and reflect on their own behavior and how it affected outcomes. They're rigid thinking also makes it difficult for them to think flexibly about an alternative solution or way of doing things. When social mistakes or missteps occur, they often misses the cues others give to indicate the exchange has not gone well.",
    },
];

const emotionalControlText = [
    {
        name: 'ECOverreactsToSeemingly',
        wont: 'Overreacts to seemingly small events',
        cant: "They can't differentiate between those situations that are a big deal and those that are a little deal. The result is a reaction that is often over the top and doesn't match the size of the problem.",
    },
    {
        name: 'ECIsHappyOne',
        wont: 'Is happy one minute and miserable the next',
        cant: 'They lack emotion regulation skills to manage the complex and unpredictable nature of the social world.',
    },
    {
        name: 'ECCriesOrMelts',
        wont: 'Cries or melts down like a much younger child',
        cant: 'They have not developed the coping strategies to manage upsetting situations.',
    },
    {
        name: 'ECWontCalmDown',
        wont: "Won't calm down once upset",
        cant: 'Their brain gets stuck on a bad feeling and takes a long time to recover from an upsetting event.',
    },
];

const categoryData = {
    Flexibility: {
        text: flexibilityText,
        title: 'Flexibility',
    },
    Organization: {
        text: organizationText,
        title: 'Organization',
    },
    Planning: {
        text: planningText,
        title: 'Planning',
    },
    Initiation: {
        text: initiationText,
        title: 'Initiation',
    },
    WorkingMemory: {
        text: workingMemoryText,
        title: 'Working Memory',
    },
    Inhibition: {
        text: inhibitionText,
        title: 'Inhibition',
    },
    SelfMonitoring: {
        text: selfMonitoringText,
        title: 'Self-Monitoring',
    },
    EmotionalControl: {
        text: emotionalControlText,
        title: 'Emotional Control',
    },
};

const UnderstandingNotReport = props => {
    const { settings } = props;

    const [devTagsData, setDevTagsData] = useState({});
    const [page1Shown, setPage1Shown] = useState(false);

    // inserting initialValues for the case that they don't yet exist through settings (Report GUI builder in app settings)
    for (const key of Object.keys(initialValues)) {
        if (!(key in settings)) {
            settings[key] = initialValues[key];
        } else {
            for (const subkey of Object.keys(initialValues[key])) {
                if (!(subkey in settings[key])) {
                    settings[key][subkey] = initialValues[key][subkey];
                }
            }
        }
    }

    useEffect(() => {
        const pawnId = Fortress.user.acting.id;

        // get user survey answers
        const getMetricsData = async () => {
            const result = await client.query({
                query: devTagsQuery,
                fetchPolicy: 'network-only',
                variables: {
                    pawnId,
                },
            });

            let queryData = {};
            queryData = result.data.flows.assignmentProgress.devTags;

            setDevTagsData(queryData);
        };

        if (!props.isPreview) {
            getMetricsData();
        }
    }, []);

    const Page1 = page1Props => {
        const qualifyingTags = getDevTagScores(devTagsData, 4).map(data => data.tag);

        // Flexibility, Organization, and Planning are supposed to appear by default if a user rates none of the executive functions in M1 as a 4 or 5, but if a user rates any of the executive functions as a 4 or 5, then Flexibility, Organization, and Planning should not appear by default (if one of them is rated a 4 or 5, then it should appear)
        const allUnder4 =
            !qualifyingTags.includes('Flexibility') &&
            !qualifyingTags.includes('Organization') &&
            !qualifyingTags.includes('Planning');
        const flexibilityQualifies = qualifyingTags.includes('Flexibility');

        if (allUnder4 || flexibilityQualifies) {
            setPage1Shown(true);
            return (
                <Page>
                    <div className={style.page} id="page1">
                        <Header showGreenBox />
                        <HeaderInfo />
                        <CategorySection devTagsData={devTagsData} title="Flexibility" rowData={flexibilityText} />
                        <Footer {...props.settings} />
                    </div>
                </Page>
            );
        }

        setPage1Shown(false);

        return <></>;
    };

    const Page2 = page2Props => {
        const qualifyingTags = getDevTagScores(devTagsData, 4).map(data => data.tag);

        // Flexibility, Organization, and Planning are supposed to appear by default if a user rates none of the executive functions in M1 as a 4 or 5, but if a user rates any of the executive functions as a 4 or 5, then Flexibility, Organization, and Planning should not appear by default (if one of them is rated a 4 or 5, then it should appear)
        const allUnder4 =
            !qualifyingTags.includes('Flexibility') &&
            !qualifyingTags.includes('Organization') &&
            !qualifyingTags.includes('Planning');
        const organizationQualifies = qualifyingTags.includes('Organization');
        const planningQualifies = qualifyingTags.includes('Planning');

        if (allUnder4 || organizationQualifies || planningQualifies) {
            return (
                <Page>
                    <div className={style.page} id="page2">
                        <Header showGreenBox />
                        {!page1Shown && <HeaderInfo />}
                        {(allUnder4 || organizationQualifies) && (
                            <CategorySection
                                devTagsData={devTagsData}
                                title="Organization"
                                rowData={organizationText}
                            />
                        )}
                        {(allUnder4 || planningQualifies) && (
                            <CategorySection devTagsData={devTagsData} title="Planning" rowData={planningText} />
                        )}
                        <Footer {...props.settings} />
                    </div>
                </Page>
            );
        }

        return <></>;
    };

    const getPages = pagesProps => {
        let qualifyingScores = getDevTagScores(devTagsData, 4);

        let pages = [];

        for (let i = 0; i < qualifyingScores.length; i += 2) {
            let devTagScoreData = qualifyingScores[i];
            while (['Flexibility', 'Organization', 'Planning'].includes(devTagScoreData.tag)) {
                i += 1;
                devTagScoreData = qualifyingScores[i];
            }

            let sections = [];

            sections.push(
                <CategorySection
                    devTagsData={devTagsData}
                    title={categoryData[devTagScoreData.tag].title}
                    rowData={categoryData[devTagScoreData.tag].text}
                    keyi={i}
                />
            );

            if (i + 1 < qualifyingScores.length) {
                devTagScoreData = qualifyingScores[i + 1];
                sections.push(
                    <CategorySection
                        devTagsData={devTagsData}
                        title={categoryData[devTagScoreData.tag].title}
                        rowData={categoryData[devTagScoreData.tag].text}
                        keyi={i + 1}
                    />
                );
            }

            pages.push(<SectionsPage sections={sections} keyi={i} />);
        }

        return pages;
    };

    const SectionsPage = sectionsProps => {
        const { sections, keyi } = sectionsProps;

        return (
            <Page key={keyi}>
                <div className={style.page} key={keyi}>
                    <Header showGreenBox />
                    {sections}
                    <Footer {...props.settings} />
                </div>
            </Page>
        );
    };

    const HeaderInfo = () => {
        return (
            <div>
                {`This report lists behaviors you've observed and the underlying brain-based reason your child
			struggles with a particular executive function. Shifting from won't thinking to can't thinking can
			help you view your child's behavior differently and apply strategies to support your child—and you.`}
            </div>
        );
    };

    return (
        <div id="report_body">
            {settings.page1.show && <Page1 {...settings.page1} devTagsData={devTagsData} settings={settings} />}
            {settings.page2.show && <Page2 {...settings.page2} devTagsData={devTagsData} settings={settings} />}
            {getPages(devTagsData)}
        </div>
    );
};

const getDevTagScores = (devTagsData, qualifyingScore) => {
    let res = [];

    Object.keys(devTagsData).forEach(tag => {
        let score = parseInt(_get(devTagsData, tag, 0), 10);
        if (score >= qualifyingScore) {
            res.push({ tag, score });
        }
    });

    return res;
};

const Header = props => {
    return (
        <>
            <div className={style.header_container}>
                <img src={logo} alt="" className={style.header_logo} />
            </div>
            <div className={style.header_line} />
            <HeaderTitle />
        </>
    );
};

const Footer = props => {
    const { footerText } = props;
    let value = _get(footerText, 'text', initialValues.footerText.text);
    if (value === '') {
        value = initialValues.footerText.text;
    }

    return (
        <div className={style.footer_container}>
            <span className={style.footer_text}>{value}</span>
            <img src={footerImage} alt="" className={style.footer_img} />
        </div>
    );
};

const HeaderTitle = () => {
    return (
        <div className={style.title}>
            <span className={style.color_green}>
                Can{"'t"}, Not Won{"'t"} |
            </span>{' '}
            {`Why Your Child Can't`}
        </div>
    );
};

const CategorySection = props => {
    const { title, rowData, devTagsData, keyi } = props;
    let maxRows = props.maxRows;
    let startingIndex = props.startingIndex;

    let rows = [];

    if (startingIndex === undefined) {
        startingIndex = 0;
    }

    if (maxRows === undefined) {
        maxRows = rowData.length;
    }

    for (let i = startingIndex; i < maxRows; i++) {
        const data = rowData[i];
        const tag = data.name;

        if (parseInt(_get(devTagsData, tag, 0), 10) === 1) {
            rows.push(<CategoryGridRow wontText={data.wont} cantText={data.cant} keyi={i} />);
        }
    }

    if (rows.length === 0) {
        rows.push(
            <div style={{ background: 'white', textAlign: 'center' }} key="none">
                None Selected
            </div>
        );
    }

    return (
        <span key={keyi}>
            <div className={style.green_box_section}>
                Executive Function: <span className={style.section_title}>{title}</span>
            </div>
            <div className={style.section_grid_container}>
                <div className={style.section_grid_row}>
                    <div className={style.dark_gray}>
                        {' '}
                        <span className={style.category_row_description}>Won{"'"}t Thinking</span>
                    </div>
                    <div className={style.dark_gray}>
                        {' '}
                        <span className={style.category_row_description}>Can{"'"}t Thinking</span>
                    </div>
                </div>
                {rows}
            </div>
        </span>
    );
};

const CategoryGridRow = props => {
    const { wontText, cantText, keyi } = props;

    return (
        <div className={style.category_data_row} key={keyi}>
            <div style={{ background: 'white' }}>
                {' '}
                <div style={{ padding: '5px' }}>{wontText}</div>
            </div>
            <div style={{ background: 'white' }}>
                {' '}
                <div style={{ padding: '5px' }}>{cantText}</div>
            </div>
        </div>
    );
};

UnderstandingNotReport.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

UnderstandingNotReport.defaultProps = {};

export default UnderstandingNotReport;
