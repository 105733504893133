import { AnswerSummary } from 'cccisd-laravel-appdefs/dist/reportTemplates';
import ExecutiveFunctionReport from './ExecutiveFunctionReport';
import UnderstandingNotReport from './UnderstandingNotReport';
import AccommodationsReport from './AccommodationsReport';
import FlexibilityReport from './FlexibilityReport';
import PlanReport from './PlanReport';
import MotivateReport from './MotivateReport';

export default [
    AnswerSummary,
    ExecutiveFunctionReport,
    UnderstandingNotReport,
    AccommodationsReport,
    FlexibilityReport,
    PlanReport,
    MotivateReport,
];
