import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import { initialValues } from '../Builder';
import devTagsQuery from './devTagsQuery.graphql';
import { client } from 'cccisd-apollo';
import _get from 'lodash/get';

// assets
import logo from './assets/Logos/logo.png';
import footerImage from './assets/Logos/footer_image.png';

const Fortress = window.cccisd.fortress;

const MotivateReport = props => {
    const { settings } = props;

    const [devTagsData, setDevTagsData] = useState({});

    // inserting initialValues for the case that they don't yet exist through settings (Report GUI builder in app settings)
    for (const key of Object.keys(initialValues)) {
        if (!(key in settings)) {
            settings[key] = initialValues[key];
        } else {
            for (const subkey of Object.keys(initialValues[key])) {
                if (!(subkey in settings[key])) {
                    settings[key][subkey] = initialValues[key][subkey];
                }
            }
        }
    }

    useEffect(() => {
        const pawnId = Fortress.user.acting.id;

        // get user survey answers
        const getMetricsData = async () => {
            const result = await client.query({
                query: devTagsQuery,
                fetchPolicy: 'network-only',
                variables: {
                    pawnId,
                },
            });

            let queryData = {};
            queryData = result.data.flows.assignmentProgress.devTags;

            setDevTagsData(queryData);
        };

        if (!props.isPreview) {
            getMetricsData();
        }
    }, []);

    return <>{settings.page1.show && <Page1 {...settings.page1} devTagsData={devTagsData} settings={settings} />}</>;
};

const getDevTagSelection = (devTagsData, tagsToCheck) => {
    if (tagsToCheck.includes('TaskSelected1')) {
        let taskSelectedValue = _get(devTagsData, 'TaskSelected1', '');
        switch (taskSelectedValue) {
            case 'task_1':
                return devTagsData.Task1;
            case 'task_2':
                return devTagsData.Task2;
            case 'task_3':
                return devTagsData.Task3;
            default:
                return taskSelectedValue;
        }
    }

    let res = '';
    Object.keys(devTagsData).forEach(tag => {
        let value = _get(devTagsData, tag, 0);
        if (tagsToCheck.includes(tag) && value) {
            res = value;
        }
    });

    return res;
};

const Header = props => {
    return (
        <>
            <div className={style.header_container}>
                <img src={logo} alt="" className={style.header_logo} />
            </div>
            <div className={style.header_line} />
            <HeaderTitle />
        </>
    );
};

const Footer = props => {
    const { footerText } = props;
    let value = _get(footerText, 'text', initialValues.footerText.text);
    if (value === '') {
        value = initialValues.footerText.text;
    }

    return (
        <div className={style.footer_container}>
            <span className={style.footer_text}>{value}</span>
            <img src={footerImage} alt="" className={style.footer_img} />
        </div>
    );
};

const HeaderTitle = () => {
    return (
        <div className={style.title}>
            <span className={style.color_green}>Motivate</span> {`Practice Motivating`}
        </div>
    );
};

const Section = props => {
    const { title, tagsToCheck, devTagsData } = props;

    const response = getDevTagSelection(devTagsData, tagsToCheck);

    return (
        <>
            <div className={style.question}>{title}</div>
            <div className={style.answer}>
                <div>{response}</div>
            </div>
        </>
    );
};

const Page1 = props => {
    const { devTagsData } = props;

    return (
        <Page>
            <div className={style.page}>
                <Header showGreenBox />
                <div>
                    {`Use the information below to guide you in motivating your child to take on a task, skill, or habit. Remember to be
specific, consistent, and patient. Track the effectiveness of the motivator and reward system in the Notes
section.`}
                </div>
                <Section
                    devTagsData={devTagsData}
                    tagsToCheck={['TaskSelected1']}
                    title="What do you want to motivate your child to do?"
                />
                <Section
                    devTagsData={devTagsData}
                    tagsToCheck={['Motivator']}
                    title="What motivator do you want to use?"
                />
                <Section
                    devTagsData={devTagsData}
                    tagsToCheck={['Reward1', 'Reward2', 'Reward3', 'Reward4', 'Reward5']}
                    title="What reward system do you want to use?"
                />
                <Section
                    devTagsData={devTagsData}
                    tagsToCheck={['ExpectedBehaviorReward']}
                    title="Describe to your child the behavior you expect from them and the reward they will
receive."
                />
                <div className={style.notes}>Notes:</div>
                <Footer {...props.settings} />
            </div>
        </Page>
    );
};

MotivateReport.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

MotivateReport.defaultProps = {};

export default MotivateReport;
